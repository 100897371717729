import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Picture from '../../../components/atoms/Picture';
import Box from '../Box';
import Typography from '../../../components/atoms/Typography';
import { pushAnaylyticsData } from '../../../../../src/utils/analytics';
import { analyticsData } from '../../../../../src/constants/Analytics/analytics-data';
import { createDynamicLabels } from '../../../../../src/utils/analytics';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import useStyles from './style';
import { get } from 'lodash';

const ImageTileWithTextOnTop = props => {
  const {
    media,
    pictureClass,
    title,
    titleClass,
    titleVariant,
    titleComponent,
    count,
    countVariant,
    countClass,
    segmentHeading,
    subTitle,
    background,
    strip,
    isPriceCard,
    pageName,
    componentName,
    disableHover,
    animate = true,
  } = props;

  const bgColor = get(background, 'fields.colorCode.value');
  const stripColor = get(strip, 'fields.colorCode.value');

  const classes = useStyles({ bgColor, stripColor });

  const addTileAnalyticsInfo = () => {
    const obj = {
      ...analyticsData.pageBodyInteraction.tileClick,
      ...{
        label: createDynamicLabels([
          pageName,
          componentName,
          `${segmentHeading && segmentHeading.value} - ${title && title.value}`,
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };

  const imgTileWrapper = useRef(),
    imgTxtTitle = useRef(),
    imgTxtSubTitle = useRef(),
    imgTxtCount = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (imgTileWrapper.current) {
      let haveAnimatedOnce = false;
      setTimeout(() => {
        gsap.timeline({
          scrollTrigger: {
            trigger: imgTileWrapper.current,
            start: 'top bottom',
            end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
            scrub: true,
            once: true,
            onUpdate: self => {
              if (self.progress > 0.5) {
                imgTxtTitle && imgTxtTitle.current && imgTxtTitle.current.animYPlay();
                imgTxtSubTitle && imgTxtSubTitle.current && imgTxtSubTitle.current.animYPlay();
                imgTxtCount && imgTxtCount.current && imgTxtCount.current.animYPlay();
                haveAnimatedOnce = true;
              } else if (haveAnimatedOnce) {
                imgTxtTitle && imgTxtTitle.current && imgTxtTitle.current.animYReverse();
                imgTxtSubTitle && imgTxtSubTitle.current && imgTxtSubTitle.current.animYReverse();
                imgTxtCount && imgTxtCount.current && imgTxtCount.current.animYReverse();
              }
            },
          },
        });
      }, 2000);
    }
  }, []);

  return (
    <Box
      onClick={() => addTileAnalyticsInfo()}
      className={clsx(classes.overlay, {
        'disableHover': disableHover
      }, 'AdcardContent')}
      ref={imgTileWrapper}
    >
      {!isPriceCard && (
        <Box className={pictureClass}>
          <Picture
            media={media}
            className={clsx(classes.cardPicture, pictureClass, {
              [classes.cardPictureWithLink]: !disableHover,
            })}
            alternateAltText={title && title.value}
            isParalax={animate}
          />
        </Box>
      )}
      <Box className={`${classes.cardContentWrapper} cardContentWrapper`}>
        {title?.value && (
          <Typography
            variant={titleVariant}
            component={titleComponent}
            classes={{ root: `${classes.cardTitle} ${titleClass}` }}
            ref={imgTxtTitle}
            animateSettings={{
              selfTrigger: false,
              willAnimate: true,
              animateType: 'y',
            }}
          >
            <Text field={title} />
          </Typography>
        )}
        {subTitle?.value && (
          <Typography
            variant="body2"
            component="div"
            classes={{ root: 'fare' }}
            ref={imgTxtSubTitle}
            animateSettings={{
              selfTrigger: false,
              willAnimate: true,
              animateType: 'y',
            }}
          >
            <Text field={subTitle} />
          </Typography>
        )}
        {count && (
          <Typography
            variant={countVariant}
            component="span"
            classes={{ root: `${classes.cardCount} ${countClass}` }}
            ref={imgTxtCount}
            animateSettings={{
              selfTrigger: false,
              willAnimate: true,
              animateType: 'y',
            }}
          >
            <Text field={count} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

ImageTileWithTextOnTop.propTypes = {
  media: PropTypes.shape({
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
    mobileImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
  pictureClass: PropTypes.string,
  title: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  titleClass: PropTypes.string,
  titleVariant: PropTypes.string,
  titleComponent: PropTypes.string,
  count: PropTypes.shape({
    value: '',
  }),
  segmentHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  countVariant: PropTypes.string,
  countClass: PropTypes.string,
};
ImageTileWithTextOnTop.defaultProps = {
  pictureClass: '',
  titleClass: '',
  titleVariant: 'h4',
  titleComponent: 'h3',
  countVariant: 'body1',
  countClass: '',
  segmentHeading: '',
};

export default ImageTileWithTextOnTop;
